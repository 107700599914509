import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";



const LoginWithMobile: React.FC = () => {
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();

    const validate = (values: APIData.mobilelogin) => {
        let errors: any = {};
        if (values.dealer_mobile == '') {
            errors.dealer_mobile = "Please Enter Your Mobile Number";
        }
        if (values.dealer_mobile.length < 10) {
            errors.dealer_mobile = "Please Enter Valid Mobile Number";
        }

        return errors;
    }



    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-login wow " data-wow-delay="0.3s">Welcome Back, Sign In</h4>
                                            <Formik initialValues={InitialData.MobileLogin} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                setShowPreloader(true);
                                                ServerAPI.sendOTP(values.dealer_mobile).then((res: any) => {
                                                    if (res != undefined) {
                                                        localStorage.setItem('hashkey',res['dealer_login_hash']);
                                                        localStorage.setItem('mobile',values.dealer_mobile);
                                                        toast.success(res['message']);
                                                        navigate(PageLinks.VERIFY_OTP_LOGIN)
                                                    }
                                                }).finally(() => {
                                                    setShowPreloader(false);
                                                })
                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (
                                                    <form className="login-form quote-form mb-20" onSubmit={handleSubmit}>
                                                        <div className="email-input mobile-input input_field pl-0">
                                                            <label className={errors.dealer_mobile && touched.dealer_mobile ? "error" : "input-title"}>Mobile Number *</label>
                                                            <input type="text" placeholder="Enter Mobile Number"
                                                                name="dealer_mobile"
                                                                maxLength={10}
                                                                size={10}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.dealer_mobile && touched.dealer_mobile ? "input-error email_input mobile_number" : "email_input mobile_number"} />
                                                            {errors.dealer_mobile && touched.dealer_mobile && (
                                                                <span className="error">{errors.dealer_mobile}</span>
                                                            )}
                                                            <div className="country_box"><img src="assets/img/icon/flag.png" /><span className="input-title-mobile"> +91</span></div>
                                                        </div>
                                                        <div className="login_bottom">
                                                            <div className="email-input mobile-input input_field pl-0">
                                                                <button type="submit" className="theme_btn btn-change primary_btn_1 sub-btn wow w-100" data-wow-delay="0.5s">Sign In</button>
                                                            </div>
                                                            <p className="review-text text-center">or Login with User Name / Unique Code?<u className="signup-terms_conditions"> <Link to={PageLinks.LOGIN}>Click Here</Link></u></p>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default LoginWithMobile;
