import { url } from "inspector";
import { Fragment, useEffect, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import Footer from "../common/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import InvestSteps from "../components/InvestSteps";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import Download from "../components/Svg/Download";
import Header from "../common/Header";
import SlideBar from "../components/SlideBar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const UserDetail: React.FC = () => {

    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);
    const [subTable, setSubTable] = useState(false);
    const toggleEarnSubTableDropdown = () => setSubTable(!subTable);
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    const location = useLocation();
    const [investmentToday, setInvestmentToday] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [search_text, setSearchText] = useState('');
    let InterstEarned = 0;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <main>
                <section className="mt-0 pt-110 pb-30 pb-md-130 pt-md-130 pt-xs-150 pb-xs-10">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Account Settings</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="sovereign_gold_bonds_detail_desc">
                    <div className="container custom-container-subs mt-3 ml-3">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 p-0">
                                    <div className="step_box_content" style={{ background: "#ffffff" }}>
                                        <div className="sub-bg facta-details">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="" data-wow-delay="0.1s">User Details</h6>
                                                            <hr></hr>
                                                        </div>
                                                        <form className="quote-form mb-10 d-lg-flex" action="#">
                                                            <div className="col-xl-6 p-0">
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">User Name</span>
                                                                    <span className="my-account-input-title-filled">Tamizh Manick</span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">User Type</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Individual
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Gender</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Male
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Employee Code</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        T002457
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Current Status</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Active
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Manage By</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Lorem ipsum
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Branch Name</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Mumbai Powai
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Date of Joining</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        18 / 11 / 2021
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 p-0">
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Date of Birth</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        18 / 11 / 1996
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Phone No</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        0428 1234567
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Mobile No</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        98500 98500
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Email ID</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        tamizhmanick@gmail.com
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Address Line 1</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        1101, Naman Centre
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Address Line 2</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        BKC, Bandra (E)
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">State</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Maharashtra
                                                                    </span>
                                                                </div>
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">City</span>
                                                                    <span className="my-account-input-title-filled">
                                                                        Mumbai
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="choice-list">
                                                        <span className="my-account-input-title">Remarks</span>
                                                        <span className="my-account-input-title-filled">
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc mt-5">
                    <div className="container custom-container-subs mt-3 ml-3">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 p-0">
                                    <div className="step_box_content" style={{ background: "#ffffff" }}>
                                        <div className="sub-bg facta-details">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="" data-wow-delay="0.1s">Reset Password</h6>
                                                            <hr></hr>
                                                        </div>
                                                        <form className="quote-form mb-10 d-lg-flex" action="#">
                                                            <div className="col-xl-12 row">
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Current Password</span>
                                                                    <span className="my-account-input-title-filled mt-1"><input placeholder="Enter Full Name" value="Kumar" className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" />
                                                                    </span>
                                                                    <span className="terms-signup-highlight cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.FORGOT_PASSWORD);
                                                                    }}>Forgot Password?</span>

                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div className="col-xl-12 row p-0">
                                                            <div className="col-xl-3">
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">New Password</span>
                                                                    <span className="my-account-input-title-filled mt-1"><input type="password" placeholder="Enter Full Name" value="1234567890" className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" /></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <div className="choice-list">
                                                                    <span className="my-account-input-title">Re-enter Your New Password</span>
                                                                    <span className="my-account-input-title-filled mt-1"><input type="password" placeholder="Enter Full Name" value="1234567890" className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" /></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <button className="user-detail-password-update cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    navigate(PageLinks.LOGINSUCCESS);
                                                                }}>Update Password</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </main >
            <Footer />
        </div >
    )
}

export default UserDetail;
