import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Subscribe: React.FC = () => {
    return (
        <section className="subscribe-area pt-50 pb-50">
            <div className="container-fluid">
                <div className="col-12 subscribe_full">
                    <div className="col-lg-7 col-md-7 col-12">
                        <div className="your_story_head">
                            <span>Subscribe to</span>
                            <h3><span className="colored_text">Bond Updates</span></h3>
                            <span className="your_story_subhead">Subscribe to get regular updates on the latest on Bonds from TrustMony.</span>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="subscribe-form quote-form mb-20">
                            <div className="email-input input_field pl-0">
                                <input type="text" placeholder="Enter your email here" />
                            </div>
                        </div>                
                        <div className="view_all float-right">
                            <a href="#" className="explore_btn">Subscribe <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
}

export default Subscribe;
