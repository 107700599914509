import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "./AppStateAPI";
import { PageLinks } from "./Constants";


const Header: React.FC = () => {

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [sortName, setSortName] = useState('');
    const [headerClass, setHeaderClass] = useState('main-header-area main-head-two header-bg');
    const [headerListClass, setHeaderListClass] = useState('non-scroll-header');
    const [headerLogo, setHeaderLogo] = useState('"assets/img/logo/header_logo_dark.svg');
    const [progressStatus, setProgressStatus] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { setShowPreloader } = useAppStateAPI();
    const [scrollTop, setScrollTop] = useState(0);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('dealer_name') != '' && localStorage.getItem('dealer_name') != undefined && localStorage.getItem('dealer_name') != null) {
            let name = localStorage.getItem('dealer_name');
            setSortName(name!.split("")[0]!);
        }
    }, [])

    useEffect(() => {
        setHeaderClass('main-header-area main-head-two header-bg');
        setHeaderLogo('../assets/img/logo/header_logo_dark.svg');
        setHeaderListClass('scroll-header');
    }, [])


    useEffect(() => {
        const onScroll = (e: any) => {
            if (window.scrollY == 0 && location.pathname == PageLinks.DASHBOARD) {
                setHeaderClass('main-header-area main-head-two header-bg');
                setHeaderLogo('../assets/img/logo/header_logo_dark.svg');
                setHeaderListClass('scroll-header');
            } else {
                setHeaderClass('main-header-area main-head-two main-bg');
                setHeaderLogo('../assets/img/logo/header_logo_white.svg');
                setHeaderListClass('non-scroll-header');
            }
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);




    return (
        <header id="top-menu" className="transparent-head sticky-header">
            {/* For Header with BG call > header-bg  */}
            <div id="headerdiv" className={headerClass}>
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                            <div className="hamburger-menu d-md-block d-lg-none">
                                <a >
                                    <i className="far fa-bars"></i>
                                </a>
                            </div>
                            <div className="logo">
                                <a className="logo-img" onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(PageLinks.PROFILE);
                                }} >
                                    <img className="logo-1a" src={headerLogo} alt="" />
                                    <img className="logo-2" src={headerLogo} alt="" />
                                    <img className="logo-3" src={headerLogo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-10 col-lg-10 col-md-6 col-6 text-right text-lg-center text-xl-right">
                            <div className="main-nav d-md-flex align-items-center justify-content-md-end">
                                <div className="main-menu d-none d-lg-block">
                                    <nav>
                                        <ul>
                                            <li><a className={headerListClass} onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.DASHBOARD);
                                            }}>Dashboard</a></li>
                                            {/* <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.ACCOUNT_SETTINGS);
                                            }} className={headerListClass} >Account Settings</a></li>
                                            <li><a className={headerListClass} >Contact Us</a></li> */}
                                        </ul>
                                    </nav>
                                </div>
                                <div className="right-nav d-flex align-items-center justify-content-end">


                                    <div className="hp-main-layout">
                                        <header>
                                            {/* {customerFirstName == undefined || customerFirstName == null || customerLastName == undefined || customerLastName == null || customerId == undefined || customerId == null ? */}
                                            {/* <Fragment>
                                                    <div className="quote-btn d-sm-block" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.LOGIN);
                                                    }}>
                                                        <p className="profile-signup trustWhitebtn">Sign In</p>
                                                    </div>
                                                </Fragment> : */}
                                            <div className="hover-dropdown-fade w-auto px-0 ms-6 position-relative hp-cursor-pointer">
                                                <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle">
                                                    {sortName}
                                                </div>
                                                <div className="quote-btn d-none d-sm-block">
                                                    <a className="theme_btn profile_top_btn">{localStorage.getItem('dealer_name')} <i className="far fa-chevron-down"></i></a>
                                                </div>
                                                <div className="hp-header-profile-menu dropdown-fade position-absolute pt-18">
                                                    <div className="border_bottom_radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80">
                                                        <div className="profile_top_section p-12">
                                                            <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle">
                                                                {sortName}
                                                            </div>
                                                            <div className="">
                                                                <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_text">Distributor</span>
                                                                <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_id">ID: 10225632</span>
                                                            </div>
                                                        </div>
                                                        <div className="row p-24">
                                                            <div className="col-12 cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                navigate(PageLinks.DASHBOARD);
                                                            }}>
                                                                <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                    <span className="hp-ml-8 profile-menu-list">Dashboard</span>
                                                                </a>
                                                            </div>
                                                            {/* <div className="col-12 cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                navigate(PageLinks.ACCOUNT_SETTINGS);
                                                            }}>
                                                                <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                    <span className="hp-ml-8 profile-menu-list">Account Settings</span>
                                                                </a>
                                                            </div> */}
                                                            <div className="col-12 cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                localStorage.clear();
                                                                navigate(PageLinks.LOGIN);
                                                            }}>
                                                                <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                    <span className="hp-ml-8 profile-menu-list">Logout</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
