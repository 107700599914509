

const SlideBar: React.FC = () => {

    return (
        <aside className="slide-bar">
            <div className="close-mobile-menu">
                <a href="javascript:void(0);"><i className="fas fa-times"></i></a>
            </div>

            {/* offset-sidebar start */}
            <div className="offset-sidebar">
                <div className="offset-widget offset-logo mb-30">
                    <a href="index.html">
                       <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                    </a>
                </div>
                <div className="offset-widget mb-40">
                    <div className="info-widget">
                        <h4 className="offset-title mb-20">About Us</h4>
                        <p className="mb-30">
                            But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain
                            was born and will give you a complete account of the system and expound the actual teachings of
                            the great explore
                        </p>
                        <a className="theme_btn theme_btn_bg" href="#">Contact Us</a>
                    </div>
                </div>
                <div className="offset-widget mb-30 pr-10">
                    <div className="info-widget info-widget2">
                        <h4 className="offset-title mb-20">Contact Info</h4>
                        <p> <i className="fal fa-address-book"></i> 23/A, Miranda City Likaoli Prikano, Dope</p>
                        <p> <i className="fal fa-phone"></i> +0989 7876 9865 9 </p>
                        <p> <i className="fal fa-envelope-open"></i> info@example.com </p>
                    </div>
                </div>
            </div>
            {/* offset-sidebar end */}

            {/* side-mobile-menu start */}
            <nav className="side-mobile-menu">
                <ul id="mobile-menu-active">
                    <li className="has-dropdown">
                        <a href="index.html">Home</a>
                        <ul className="sub-menu">
                            <li><a href="index.html">Home Style 1</a></li>
                            <li><a href="index-2.html">Home Style 2</a></li>
                            <li><a href="index-3.html">Home Style 3</a></li>
                            <li><a href="index-dark.html">Home Dark 1 </a></li>
                            <li><a href="index-dark-2.html">Home Dark 2</a></li>
                            <li><a href="index-dark-3.html">Home Dark 3</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Insurance</a></li>
                    <li className="has-dropdown">
                        <a href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><a href="about.html">About us</a></li>
                            <li><a href="case-one.html">Case Study</a></li>
                            <li><a href="price.html">Price</a></li>
                            <li><a href="testimonial.html">Testimonial</a></li>
                            <li><a href="team.html">Team</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="error.html">Error</a></li>
                        </ul>
                    </li>
                    <li className="has-dropdown"><a href="#">Blogs</a>
                        <ul className="sub-menu">
                            <li><a href="blog-grid.html">Blog Grid</a></li>
                            <li><a href="blog-standard.html">Blog Standard</a></li>
                            <li><a href="blog-details.html">Blog Details</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Contacts Us</a></li>
                </ul>
            </nav>
            {/* side-mobile-menu end */}
        </aside>
    )
}

export default SlideBar;