import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { API, Endpoints } from "./Constants";
import { APIData } from "./DataTypes";

export namespace ServerAPI {

    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    }

    export const sendOTP = async (mobile: string) => {
        let data: { [k: string]: any } = {};
        data["dealer_mobile"] = mobile;
        return executeAPI(API.EndPoint.sendOTP, APIMethod.POST, false, data);

    }

    export const Login = async (username: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["dealer_user_name"] = username;
        data["dealer_password"] = password;
        return executeAPI(API.EndPoint.login, APIMethod.POST, false, data);

    }

    export const ChangePassword = async (dealerID: number, password: string) => {
        let data: { [k: string]: any } = {};
        data["dealer_id"] = dealerID;
        data["dealer_password"] = password;
        return executeAPI(API.EndPoint.setPassword, APIMethod.POST, false, data);
    }


    export const getIpoList = async (search_text:string) => {
        let params: { [k: string]: any } = {};
        params['search_text'] = search_text;
        return executeAPI(API.EndPoint.Ipolist, APIMethod.GET, true, null, params);
    }

    export const getAllocationIpoList = async () => {
        let params: { [k: string]: any } = {};
        params["limit"] = 50;
        params["page_number"] = 1;
        return executeAPI(API.EndPoint.AllocatingIpo, APIMethod.GET, true, null, params);
    }

    export const VerifyOTP = async (OTP: string) => {
        let data: { [k: string]: any } = {};
        data["dealer_mobile"] = localStorage.getItem('mobile');
        data["dealer_login_otp"] = OTP;
        data["dealer_login_hash"] = localStorage.getItem('hashkey');
        return executeAPI(API.EndPoint.OTPVerify, APIMethod.POST, false, data);
    }
    export const GenerateForm = async (bondID: number, pagetype: number, apllications: number) => {
        let data: { [k: string]: any } = {};
        data["bond_id"] = bondID;
        data["page_type"] = pagetype;
        data["no_of_applications"] = apllications;
        return executeAPI(API.EndPoint.IPOFormGenerate, APIMethod.POST, true, data);
    }

    export const GetFormAllocations = async (bondID: number) => {
        let params: { [k: string]: any } = {};
        params["bond_id"] = bondID;
        return executeAPI(API.EndPoint.IPOFormAllocations, APIMethod.GET, true, null, params);
    }

    export const ipoFormAllocationList = async (limit: number, pageNumber: number, bondID: number, allocationType: number,search_text:string) => {
        let params: { [k: string]: any } = {};
        params["limit"] = limit;
        params["page_number"] = pageNumber;
        params["bond_id"] = bondID;
        params["dealer_customer_allocation_type"] = allocationType;
        return executeAPI(API.EndPoint.IPOFormAllocationList, APIMethod.GET, true, null, params);
    }

    export const ipoFormAllocation = async (dealer_customer_allocation_id: number, dealer_customer_allocation_customer_name: string, dealer_customer_allocation_email: string, dealer_customer_allocation_phone: string, dealer_customer_allocation_via_email: number, dealer_customer_allocation_via_whatsapp: number) => {
        let data: { [k: string]: any } = {};
        data["dealer_customer_allocation_id"] = dealer_customer_allocation_id;
        data["dealer_customer_allocation_customer_name"] = dealer_customer_allocation_customer_name;
        data["dealer_customer_allocation_email"] = dealer_customer_allocation_email;
        data["dealer_customer_allocation_phone"] = dealer_customer_allocation_phone;
        data["dealer_customer_allocation_via_email"] = dealer_customer_allocation_via_email;
        data["dealer_customer_allocation_via_whatsapp"] = dealer_customer_allocation_via_whatsapp;
        return executeAPI(API.EndPoint.IPOFormAllocations, APIMethod.POST, true, data);
    }



    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean,
        returnError?: boolean
    ) => {
        let headers: { [k: string]: any } = {};
        const token = localStorage.getItem('token');
        headers["Authorization"] = token;
        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }

        try {
            const response = await axios({
                method: method,
                url: API.BaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            return response.data;

        } catch (error) {
            if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        toast.error(errorData.errors);
                    } else {
                        toast.error(errorData.errors);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
            if (axios.isAxiosError(error) && error.response && returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        return (errorData.errors);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
        }


    }
}
