import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const ForgotPasswordSuccess: React.FC = () => {

    const location = useLocation();
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();


    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center cursor" onClick={(e: any) => {
                    e.preventDefault();
                    navigate(PageLinks.CHANGEPASSWORD);
                }}>
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s">
                        <div className="">
                            <div className="icon icon_img">
                                <img src="assets/img/email-tick.png" alt="" />
                            </div>
                            <p className="otp_popup_text" data-wow-delay="0.1s">Check your mail</p>
                            <p className="success-popup-text mt-2" data-wow-delay="0.1s">We have sent a password reset link to&nbsp;<span style={{ color: "#22263D" }}>tamizhmanick@gmail.com</span></p>
                        </div>
                        <div className="col-xl-12 continue_btn_whole cursor" onClick={(e: any) => {
                            navigate(PageLinks.LOGIN);
                        }}>
                        </div>
                    </div>
                </div>
                <p className="review-text text-center">Don't receive the email?<u className="signup-terms_conditions cursor" onClick={(e:any)=>{
                    e.preventDefault();
                    navigate(PageLinks.FORGOT_PASSWORD);
                }}>Click to resend</u></p>
            </div>
        </section>
    )
}

export default ForgotPasswordSuccess;