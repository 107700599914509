import moment from "moment";
import { ServerAPI } from "./ServerAPI";

export const LoginDetails = ( dealerName: string,refreshToken: string, token: string) => {
  localStorage.setItem('token', token ? token : '');
  localStorage.setItem('dealer_name', dealerName ? dealerName + "" : '');
  localStorage.setItem('refresh_token', refreshToken ? refreshToken : '');
}

export const getFormattedLocalDateTime = (utcDateTime: string) => {
  if (utcDateTime && utcDateTime != '') {
    return moment(utcDateTime).format('DD MMM YYYY hh:mm a');
  }
  return 'N/A';
}

export const numberWithCommas = (x: any) => {
  if (x != "" && x != null) {
    var res = parseInt(x).toLocaleString("en-IN");
    return res;
  } else {
    return 0;
  }
}

