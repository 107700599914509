import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { LoginDetails } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";



const Login: React.FC = () => {
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();


    const validate = (values: APIData.login) => {
        let errors: any = {};
        if (values.dealer_user_name == '') {
            errors.dealer_user_name = "Please Enter Your UserName/Unique Code";
        }
        if (values.dealer_password == '') {
            errors.dealer_password = "Please Enter Your Password";
        }

        return errors;
    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-register wow " data-wow-delay="0.3s">Welcome Back, Sign In</h4>
                                            <Formik initialValues={InitialData.login} validate={validate} enableReinitialize={true} onSubmit={(values, { setSubmitting }) => {
                                                setShowPreloader(true);
                                                ServerAPI.Login(values.dealer_user_name, values.dealer_password).then((res: any) => {
                                                    if (res != undefined) {
                                                        const userDetail = res;
                                                        LoginDetails(userDetail["dealer_name"],userDetail["refresh_token"], userDetail["token"]);
                                                        toast.success(res['message']);
                                                        navigate(PageLinks.DASHBOARD)
                                                    }
                                                }).finally(() => {
                                                    setShowPreloader(false);
                                                })
                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="login-form quote-form mb-30">
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.dealer_user_name && touched.dealer_user_name ? "error" : "input-title"}>User Name/Unique Code*</label>
                                                                <input onChange={handleChange} onBlur={handleBlur} type="text" placeholder="Enter User Name"
                                                                    name="dealer_user_name" />
                                                                {errors.dealer_user_name && touched.dealer_user_name && (
                                                                    <span className="error">{errors.dealer_user_name}</span>
                                                                )}
                                                            </div>
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.dealer_password && touched.dealer_password ? "error" : "input-title"}>Password*</label>
                                                                <input onChange={handleChange} onBlur={handleBlur} type="password" placeholder="Enter Password"
                                                                    name="dealer_password" />
                                                                {errors.dealer_password && touched.dealer_password && (
                                                                    <span className="error">{errors.dealer_password}</span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        <div className="email-input mobile-input input_field pl-0 login-btn mt-5">
                                                            <button className={(values.dealer_user_name != "" && values.dealer_password != "") ? "theme_btn btn-change primary_btn_1 sub-btn wow w-48" : "theme_btn primary_btn sub-btn wow w-48"}  data-wow-delay="0.5s" type="submit">Sign In</button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                            <p className="review-text text-center mt-4">or Login with Mobile No?&nbsp;<u className="signup-terms_conditions"> <Link to={PageLinks.LOGIN_WITH_MOBILE}>Click here</Link></u></p>
                                            <p className="review-text text-center mt-2 cursor" onClick={(e:any)=>{
                                                e.preventDefault();
                                                navigate({
                                                    pathname: PageLinks.CHANGEPASSWORD + "/" + localStorage.getItem('dealerid'),
                                                  });
                                            }}>Forgot Password ?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default Login;
