import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";



const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-register wow " data-wow-delay="0.3s">Forgot Password</h4>
                                            <form>
                                                <div className="login-form quote-form mb-30">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="input-title">Enter Your Email ID</label>
                                                        <input type="text" placeholder="Enter User Email ID"
                                                            name="firstName" />
                                                    </div>
                                                </div>
                                                <div className="email-input mobile-input input_field pl-0 login-btn mt-5">
                                                    <button className="theme_btn primary_btn sub-btn wow w-48" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.PASSWORD_CHANGE_SUCCESS)
                                                    }} data-wow-delay="0.5s" type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default ForgotPassword;
