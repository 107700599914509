import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { PageLinks } from "./Constants";

const Footer: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const year = new Date().getFullYear();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <footer className="footer-area fix">
            <div className="container-fluid">
                <div className="row hr-border pt-50">
                    <div className="col-xl-3 col-lg-4 col-md-6  " data-wow-delay='.1s'>
                        <div className="footer__widget text-center text-md-left mb-30">
                            <div className="footer-log mb-10">
                                <a href="#" className="logo">
                                    <img src="../assets/img/logo/header_logo_white.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-4 col-md-6  " data-wow-delay='.5s'>
                        <div className="footer__widget text-left text-md-left mb-25 pl-85">
                            <h4 className="widget-title mb-20">Quick Link</h4>
                            <ul className="fot-list">
                                <li><Link to={PageLinks.ABOUTUS}>About Us</Link></li>
                                <li><Link to={PageLinks.NEWSANDINSIGHTS}>News and Insights</Link></li>
                                <li><Link to={PageLinks.CONTACT_US}>Contact Us</Link></li>
                                <li><Link to={PageLinks.PRIVACY_POLICY}>Privacy Policy</Link></li>
                                <li><Link to={PageLinks.TERMS_AND_CONDITIONS}>Terms & Conditions</Link></li>
                                <li><Link to={PageLinks.DISCLAIMER}>Disclaimer</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col-xl-3 col-lg-6 col-md-6  " data-wow-delay='.7s'>
                        <div className="footer__widget text-left text-md-left mb-30 pl-30 pl-lg-0 pl-md-0 pl-xs-0">
                            <h4 className="widget-title mb-20">Talk to expert</h4>
                            <ul className="fot-list">
                                <li><a href="#">Write to Us</a></li>
                                <li><a href="#">Login To Chat</a></li>
                            </ul>
                            <div className="footer-social mt-20 mb-40">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                                <a href="#"><i className="fab fa-medium-m"></i></a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="copy-right-area pt-10">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="copyright mb-40 text-center">
                                <p>© {year} Trust Capital Pvt Ltd. | CIN: U72123MH1234PTC123456</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="home_chat_icon">
                <span id="home_chat" onClick={handleClick} >
                    <img src="assets/img/icon/home_chat.svg" />
                </span>
            </div> */}
            <Popover
                id="home_chat"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <div className="call-option-content home_chat_cont">
                        <h5>Need help to find bonds</h5>
                        <h6>Learn how to pick quality bonds <br></br> and start investing...  </h6>
                        <Link to={PageLinks.CHAT}><button className="alert-button"><span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button></Link>
                    </div>
                    
                </Typography>
            </Popover>
        </footer>
        
        )
}

export default Footer;
