import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";



const ChangePassword: React.FC = () => {
    const { setShowPreloader } = useAppStateAPI();
    const [dealerID,setDealerID] = useState(0);
    const location = useLocation();
    const baseurl = window.location.origin;

    const validate = (values: APIData.password) => {
        let errors: any = {};
        if (values.newPassword != values.confirmPassword) {
            errors.confirmPassword = "Your Password's Does Not Match !";
        }
        if (values.newPassword == '') {
            errors.newPassword = "Please Enter Your Password";
        }

        if (values.confirmPassword == '') {
            errors.confirmPassword = "Please Enter Your Confirm Password";
        }

        if (values.terms == false) {
            errors.terms = "Please Accept Terms and Conditions";
        }

        return errors;
    }

    useEffect(() => {
        if (location.pathname) {
          const id = location.pathname.split("/").pop();
          console.log(id);
          setDealerID(parseInt(id!));
          localStorage.setItem('dealerid',id+"");
        }
      }, [location]);

    const navigate = useNavigate();
    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src={baseurl+"/assets/img/Intersection_4.svg"} alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src={baseurl+"/assets/img/logo/header_logo_one.png"} className="main_logo" alt="logo" />
                                            <h4 className="sub-title-register wow " data-wow-delay="0.3s">Change Password</h4>
                                            <Formik initialValues={InitialData.Password} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                console.log(dealerID);
                                                setShowPreloader(true);
                                                ServerAPI.ChangePassword(dealerID?dealerID:0,values.newPassword).then((res: any) => {
                                                if (res != undefined) {
                                                    toast.success(res['message']);
                                                    navigate(PageLinks.LOGIN)
                                                }
                                                }).finally(() => {
                                                    setShowPreloader(false);
                                                })
                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="login-form quote-form mb-30">
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.newPassword && touched.newPassword ? "error" : "input-title"}>New Password</label>
                                                                <input onChange={handleChange} onBlur={handleBlur} type="password" placeholder="Enter Password"
                                                                    name="newPassword" />
                                                                {errors.newPassword && touched.newPassword && (
                                                                    <span className="error">{errors.newPassword}</span>
                                                                )}
                                                            </div>
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.confirmPassword && touched.confirmPassword ? "error" : "input-title"}>Re-enter Your New Password</label>
                                                                <input onChange={handleChange} onBlur={handleBlur} type="password" placeholder="Enter Password"
                                                                    name="confirmPassword" />
                                                                {errors.confirmPassword && touched.confirmPassword && (
                                                                    <span className="error">{errors.confirmPassword}</span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        <div className="d-flex login-form quote-form">
                                                            <input className=" signup" type="checkbox" onChange={handleChange} onBlur={handleBlur} id="terms" name="terms" />&nbsp;&nbsp;
                                                            <label htmlFor="terms" className="m-0">
                                                                <p className="review-text text-left mt-2">
                                                                    I accept the <u className="signup-terms_conditions"><span className="terms-signup-highlight">Terms &amp; Conditions</span></u>
                                                                </p>
                                                            </label>
                                                        </div>
                                                        {errors.terms && touched.terms && (
                                                            <p className="error_text d-flex login-form quote-form mb-5">{errors.terms}</p>
                                                        )}
                                                        <div className="email-input mobile-input input_field pl-0 login-btn">
                                                            <button className={(values.terms != false && values.newPassword != "" && values.confirmPassword != "" && values.newPassword == values.confirmPassword) ? "theme_btn btn-change primary_btn_1 sub-btn wow w-48" : "theme_btn primary_btn sub-btn wow w-48"} data-wow-delay="0.5s" type="submit">Change Password</button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default ChangePassword;
