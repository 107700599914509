import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const AllocationSuccess: React.FC = () => {

    const location = useLocation();
    const preData: any = location.state;
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const pagenavigate = () =>{
        navigate(-1);
    }

    setTimeout(pagenavigate, 3000);


    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center cursor" onClick={(e:any)=>{
                    e.preventDefault();
                    navigate(-1);
                }}>
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s" >
                        <div className="mb-3">
                            <div className="icon icon_img">
                                <img src="assets/img/email-tick.png" alt="" />
                            </div>
                            <p className="otp_popup_text" data-wow-delay="0.1s">Your Message has been successfully sent.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AllocationSuccess;