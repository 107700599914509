import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const LoginSuccess: React.FC = () => {

    const location = useLocation();
    const preData: any = location.state;
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();


    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center cursor">
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s">
                        <div className="mb-3">
                            <div className="icon mb-25 icon_img">
                                <img src="assets/img/icon/Thumbs_up.png" alt="" />
                            </div>
                            <p className="otp_popup_text" data-wow-delay="0.1s">Password Changed</p>
                            <p className="success-popup-text mt-2" data-wow-delay="0.1s">Please Log in with the new Password.</p>
                        </div>
                        <div className="col-xl-12 continue_btn_whole cursor" onClick={(e: any) => {
                            navigate(PageLinks.LOGIN);
                        }}>
                            <button className="theme_btn primary_btn_one sub-btn wow mt-3" data-wow-delay="0.5s">Log In</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginSuccess;