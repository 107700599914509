
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Header from "../common/Header";
import SlideBar from "../components/SlideBar";
import Footer from "../common/Footer";



import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';



const Faq: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="plan-coverage-area aboutus_page pos-rel about-style-1 pt-110 pb-50 pt-lg-50 pt-md-110 pb-md-30 pt-xs-10 pb-xs-30">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">FAQs</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-5 offset-lg-1">
                                <div className="about_left_img">
                                    <img src="assets/img/icon/faq.png" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="plan-title-wrapper mb-30 pl-95 pl-lg-0 pl-md-0 pl-xs-0">
                                    <div className="section-title mb-55">
                                        <h3 className="mb-45">FAQS</h3>
                                        <h4 className="sub-title">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-10 offset-lg-1">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Why should one invest in fixed income securities?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                While many investments provide some form of income, fixed income securities tend to offer fixed and the most reliable cash streams; these are rated by credit rating agencies, allowing investors to choose bonds from financially stable issuers. A diversified bond portfolio can provide decent yield with a lower level of volatility than equities or any other asset classes. Fixed income securities at maturity provide investors with the principal amount they had invested in addition to the interest they have received.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What are the advantages of investing in bonds?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Stability of principal : One advantage of investing in fixed income securities is peace of mind that comes from capital preservation. Investors benefit by investing in fixed income securities as they preserve and increase their invested capital.

                                                Generates a steady and regular income stream : Fixed income securities provide investors with a steady stream of income, For example, by investing Rs. 1,00,000/- in bond with 12% annual coupon rate, the investor has the assurance to get Rs. 12,000 directly in the bank account on yearly basis till the maturity of the bond.

                                                High priority claims to assets : Fixed income investors get benefit from their position in the capital structure of an entity (issuer) issuing both equity and debt investments. Investors in bonds of a corporation have a higher priority over common and preferred stockholders of the same entity (issuer).

                                                Nullify market volatility : The price of debt securities displays a very lower average volatility as compared to the price of equity or mutual funds and ensures the greater safety of the investment.

                                                Zero credit risk : Investors can neutralize the default risk on their investments by investing in government securities, which are normally referred to as risk-free investments due to the sovereign guarantee on these instruments.

                                                Efficient portfolio diversification : Fixed income securities enable efficient portfolio diversification and thus assist in portfolio risk mitigation.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is a bond?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A bond is a fixed income instrument that represents a loan made by an investor (bond holder) to a borrower (bond issuer). Governments, corporates, banks, and municipalities issue bonds when they need capital. Bonds traditionally pay a fixed interest rate (coupon) to the debtholders along with the principal amount at maturity.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is a maturity date?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A bond pays interest periodically and repays the principal at a stated time, known as maturity.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is a coupon rate?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Coupon rate is the periodic rate of interest paid by bond issuers to their purchasers. The coupon rate is calculated on the bond’s face value (or par value), not on the issue price or market value. For example, if you have a 10-year- Rs 2,000 bond with a coupon rate of 10%, you will get Rs 200 every year for 10 years, no matter what happens to the bond price in the market.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the difference between yield to maturity (YTM) and current yield (CY)?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Yield to maturity (YTM) is the total return anticipated on a bond if the bond is held until it matures. In other words, it is the internal rate of return (IRR) of an investment in a bond if the investor holds the bond until maturity. Current yield helps investors drill down on bonds that generate the greatest returns on investment each year, which is equal to an investment's annual income (coupon) divided by the current price of the bond.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the taxation aspect of bonds
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The interest on bonds, except tax-free bonds, is taxable at the slab rates applicable to you. Any profit on sale/redemption will become taxable as long-term if the same is held for more than 36 months on the date of such sale/redemption whereas profits made on the sale of bonds shall be taxed as short-term, in case they have been held for 36 months or less, however in the case of the bonds which are listed on any stock exchange in India, the qualifying period is 12 months instead of 36 months.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the difference between coupon rate and yield?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A coupon is the fixed interest rate paid to the bond holders, whereas a bond's yield refers to the expected earnings generated and realized on a fixed-income investment over a particular period of time, expressed as a percentage of interest rate.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is a call/put option?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A bond option is a contract that gives an investor or issuer the right to buy or sell a bond by a particular date for a predetermined price. The call option allows the issuer the right to buy back the bond at a predetermined price at a certain time (call date) in the future, whereas a put option allows the holder right to demand early redemption at a predetermined price at a certain time (put date) in the future. The bond option can be used to protect an existing bond portfolio against adverse interest rate movements or to make a profit on favorable, short-term movements in prices.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What do perpetual bonds mean?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A perpetual bond is a bond with no fixed maturity date. While perpetual bonds can be understood to be debt obligations, the obligation here isn’t compulsory, this is owed to the fact that the debt isn’t required to be repaid by the issuer till they continue to make interest or coupon payments that are owed to those that hold perpetual bonds. Typically, these bonds are issued with call date and are callable at the predefined call date. The yield of a perpetual bond is therefore calculated up to the earliest call date.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What type of fixed income instruments are available for investment?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                <ul>
                                                    <li><i className="fal fa-check-circle"></i> Taxable bonds/NCDs issued by PSUs, banks, corporates, NBFCs, municipal corporations, and financial institutions</li>
                                                    <li><i className="fal fa-check-circle"></i> Government guaranteed bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Tax-free bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Government bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Perpetual bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Fixed deposits (FDs) of various corporates, PSUs and NBFCs</li>
                                                    <li><i className="fal fa-check-circle"></i> High yielding bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Zero-coupon/deep discount bonds</li>
                                                    <li><i className="fal fa-check-circle"></i> Floating rate savings bonds 2020 (Taxable)</li>
                                                    <li><i className="fal fa-check-circle"></i> Commercial papers (CPs)</li>
                                                    <li><i className="fal fa-check-circle"></i> Certificate of deposits (CDs)</li>
                                                    <li><i className="fal fa-check-circle"></i> Capital gain tax bonds (54 EC)</li>
                                                </ul>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Who are the issuers in the debt market?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                <ul>
                                                    <li><i className="fal fa-check-circle"></i> Central & state government (through RBI)</li>
                                                    <li><i className="fal fa-check-circle"></i> Public sector undertakings (PSUs)</li>
                                                    <li><i className="fal fa-check-circle"></i> Corporates</li>
                                                    <li><i className="fal fa-check-circle"></i> Banks</li>
                                                    <li><i className="fal fa-check-circle"></i> NBFCs</li>
                                                    <li><i className="fal fa-check-circle"></i> Financial institutions</li>
                                                    <li><i className="fal fa-check-circle"></i> Municipal corporations</li>
                                                </ul>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Who regulates the bond market?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The bond market is regulated by RBI & SEBI.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Where are these bonds traded?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Most of the bonds are traded over-the-counter between large broker-dealers acting on their clients or their own behalf whereas some of the bonds are traded publicly through exchanges.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the face value of the bond?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Face value is the amount the investor will get back from the issuer once the debt instrument matures. In financial terms, face value is the nominal value of the bond, which differs from issue to issue and is dependent on the issuer.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Are all bonds secured?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A bond can be secured or unsecured. Secured bond pledges specific assets to bondholders in case the company cannot repay the obligation. This asset is also called collateral on the loan.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Are these bonds guaranteed?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Not all bonds carry a specific guarantee. However, some bonds are issued with a guarantee by either the parent company, third party, or the government/government-owned company.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How the bonds are issued?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The bond issuance process varies based on bond type. Corporate bonds are issued through private placement or public issues whereas government bonds are issued through the RBI auction mechanism.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Are these bonds listed?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Most bonds are listed on stock exchanges (BSE/NSE). G-Secs / SDLs are issued by the government and thus considered as deemed listed bonds and do not require any specific listing unlike bonds issued by corporate entities.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the day count convention?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Day count convention is to determine the number of days and the amount of accrued interest between two coupon dates.
                                            </p>
                                            <p><b>There are 4 different conventions-</b></p>
                                            <p>
                                                <ul>
                                                    <li><i className="fal fa-check-circle"></i> Actual/Actual convention - Uses the actual number of days between two periods, it is often used for corporate bonds.</li>
                                                    <li><i className="fal fa-check-circle"></i> 30/360 day count convention - Days in each month are considered as 30 days and here a year consists of 360 days; it is often used in the calculation of accrued interest for the G-secs.</li>
                                                    <li><i className="fal fa-check-circle"></i> Actual/360 day count convention - This convention is most commonly used when calculating the accrued interest for commercial papers, T-bills, and other short-term debt instruments.</li>
                                                    <li><i className="fal fa-check-circle"></i> Actual/365 day count convention - Is the same as Actual/360 except for the fact that we will use 365 instead of 360.</li>
                                                </ul>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is accrued interest and holding period interest?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Accrued interest is the interest on a bond that has accumulated since the previous coupon payment. In simple terms, accrued interest is holding period interest which the seller of the bond takes from the buyer of the bond. Accrued interest = (Days between the last interest payment date and purchase date/total number of days in a payment period) * (Coupon rate /100) * (Number of bonds * Face value of bond). Bond investors are not obligated to take an issuer's bond and hold it until maturity. The interest on a bond over the period in which it was held is called the holding period interest (HPI).
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How will I receive interest on bonds?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                In the case of corporate and government bonds, interest will be credited in the bank account linked with Demat directly by the issuer on interest payment dates.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How is the bond price determined?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The bond price is determined by discounting the expected cash flow to the present using a discount rate. Four primary influences on bond pricing on the open market are supply and demand, age-to-maturity, credit ratings, and economic factors.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Are these bonds rated?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A bond rating is a way to measure the creditworthiness of a bond. Most bonds and corporate FDs are rated by SEBI-regulated rating agencies like CRISIL, ICRA, CARE, India Ratings. Ratings from these agencies vary from AAA (highest degree of safety) to D (default rating). However, there are unrated bonds issuances too typically real estate structured obligations.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the minimum investment amount?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Minimum investment amount ranges between Rs. 10,000 to Rs. 10,00,000 depending on product type.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Can I use the existing Demat account for the bond transaction as well?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                You can use your existing Demat account for investing in bonds.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Are rates shown on the system fixed?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Rates shown on the system are indicative rates and it is subject to change based on market conditions.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Faq;