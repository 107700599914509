import React, { Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { AppStateProvider } from './common/AppState';
import ScrollToTop from './common/ScrollToTop';
import { PageLinks } from './common/Constants';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import LoginSuccess from './pages/LoginSuccess';
import Faq from './pages/Faq';
import AboutUs from './pages/AboutUs';
import WhatAreAdvantages from './pages/WhatAreAdvantages';
import LearnFromBond from './pages/LearnFromBond';
import Dashboard from './pages/Dashboard';
import ForgotPasswordSuccess from './pages/ForgotPasswordSuccess';
import DashboardDetail from './pages/DashboardDetail';
import AllocationSuccess from './pages/AllocationSuccess';
import UserDetail from './pages/UserDetail';
import ForgotPassword from './pages/ForgotPassword';
import LoginWithMobile from './pages/LoginWithMobile';
import VerifyOTPLogin from './pages/VerifyOTPLogin';
import AppToast from './common/Toaster';

function App() {
  return (
    <Fragment>
      <AppToast />
      <AppStateProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path={PageLinks.LOGIN} element={<Login />} />
            <Route path={PageLinks.CHANGEPASSWORD+"/*"} element={<ChangePassword />} />
            <Route path={PageLinks.LOGINSUCCESS} element={<LoginSuccess />} />
            <Route path={PageLinks.FAQ} element={<Faq />} />
            <Route path={PageLinks.ABOUTUS} element={<AboutUs />} />
            <Route path={PageLinks.WHAT_ARE_ADVANTAGES} element={<WhatAreAdvantages />} />
            <Route path={PageLinks.LEARN_FROM_BOND} element={<LearnFromBond />} />
            <Route path={PageLinks.DASHBOARD} element={<Dashboard />} />
            <Route path={PageLinks.PASSWORD_CHANGE_SUCCESS} element={<ForgotPasswordSuccess />} />
            <Route path={PageLinks.DASHBOARD_DETAIL + "/*"  } element={<DashboardDetail />} />
            <Route path={PageLinks.ALLOCATION_SUCESS} element={<AllocationSuccess />} />
            <Route path={PageLinks.ACCOUNT_SETTINGS} element={<UserDetail />} />
            <Route path={PageLinks.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={PageLinks.LOGIN_WITH_MOBILE} element={<LoginWithMobile />} />
            <Route path={PageLinks.VERIFY_OTP_LOGIN} element={<VerifyOTPLogin />} />
            <Route path="/" element={<Navigate to={PageLinks.LOGIN} />} />
          </Routes>
        </Router>
      </AppStateProvider>
    </Fragment>
  );
}

export default App;
