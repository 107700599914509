import { APIData } from "../../common/DataTypes";
import moment from "moment";

export namespace InitialData {
    export const Password: APIData.password = {
        newPassword: "",
        confirmPassword: "",
        terms: false
    }

    export const login: APIData.login = {
        dealer_user_name: "",
        dealer_password: ""
    }

    export const MobileLogin: APIData.mobilelogin = {
        dealer_mobile: "",
    }

    export const MobileOTP: APIData.otp_verify = {
        otp: "",
    }

    export const AllocateApplication: APIData.IpoGenerate = {
        bond_id: 0,
        page_type: 0,
        no_of_applications: 0
    }
}