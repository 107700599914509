import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { APIData } from "../../common/DataTypes";
import { Field, Formik } from "formik";
import { InitialData } from "../Form/InitialData";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    ipoList:any,
    BondID?:number,
    AllocationList?:any

}

const ApplicationPopup: React.FC<FormParameters> = ({ showModal, closeModal,ipoList,BondID,AllocationList }) => {
    const { setShowPreloader } = useAppStateAPI();
    const [dematType, setDematType] = useState('');
    const [ipoLists, setIpoList] = useState<APIData.AllocatingIPOlist[]>([]);
    const [ipoGenerate, setIpoGenerate] = useState<APIData.IpoGenerate>(InitialData.AllocateApplication);
    const [qty, setQty] = useState(1);


    useEffect(() => {
        ipolistAllocation();
    }, [])

    const ipolistAllocation = () => {
        setShowPreloader(true);
        ServerAPI.getAllocationIpoList().then((response: any) => {
            if (response != undefined) {
                setIpoList(response['total_count']);
            }

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(()=>{
        setQty(1);
    },[closeModal,showModal])




    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="sm"
                >
                    <DialogTitle>Application Form
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Formik initialValues={ipoGenerate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                setShowPreloader(true);
                                ServerAPI.GenerateForm(BondID?BondID:values.bond_id, values.page_type, qty).then((response: any) => {
                                    if (response != undefined) {
                                        toast.success(response['message']);
                                        ipoList();
                                        AllocationList();
                                        closeModal();
                                    }
                                }).finally(() => {
                                    setShowPreloader(false);
                                })
                            }}>
                                {({
                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                }) => (

                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                        <div className="col-xl-12 pl-0 float-left">
                                            <div className="col-xl-12 pl-0 float-left">
                                                <div className="email-input input_field pl-0">
                                                    <label className={errors.bond_id && touched.bond_id ? "error" : "input-title"}>Select IPO Bond Name *</label>
                                                    <Field as="select" className="" name="bond_id" value={BondID?BondID:0} onChange={handleChange} onBlur={handleBlur} disabled={BondID?true:false}>
                                                        <option value={0}>select</option>
                                                        {ipoLists != undefined && ipoLists.length > 0 && ipoLists.map((ipo: APIData.AllocatingIPOlist, index) => {
                                                            return (
                                                                <option key={index} value={ipo.bond_id + ""} >{ipo.bond_name}</option>
                                                            )
                                                        })

                                                        }
                                                    </Field>
                                                </div>

                                            </div>
                                            <div className="col-xl-12 pl-0 float-left">
                                                <div className="email-input input_field pl-0">
                                                    <label className={errors.page_type && touched.page_type ? "error" : "input-title"}>Select Form Type *</label>
                                                    <Field as="select" className="" name="page_type" onChange={handleChange} onBlur={handleBlur}>
                                                        <option>select</option>
                                                        <option value={1}>Single Page</option>
                                                        <option value={2}>Two Page</option>
                                                        <option value={3}>Abridged</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 float-left row">
                                                <div className="col-xl-6">
                                                    <p className="mt-3">No of application Forms</p>
                                                </div>
                                                <div className="email-input input_field col-xl-6 row">
                                                    <div className="col-lg-2 p-0 box-shadow">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            if (qty != 1) {
                                                                setQty(qty - 1);
                                                            }
                                                        }} className="detail-view-calculator-button">-</button>
                                                    </div>&nbsp;
                                                    <div className="col-lg-7">
                                                        <input value={qty} className="input-value1 box-shadow text-center" />
                                                    </div>
                                                    <div className="col-lg-2 p-0 box-shadow">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setQty(qty + 1);
                                                        }} className="detail-view-calculator-button">+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                            <button
                                                className={"theme_btn btn-change primary_btn_1 sub-btn wow"}
                                                data-wow-delay="0.5s" type="submit">Download Now</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default ApplicationPopup;