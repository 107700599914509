import { url } from "inspector";
import { Fragment, useEffect, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import Footer from "../common/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import InvestSteps from "../components/InvestSteps";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import Download from "../components/Svg/Download";
import Header from "../common/Header";
import SlideBar from "../components/SlideBar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toast } from "react-hot-toast";
import ApplicationPopup from "../components/Popups/ApplicationPopup";


const DashboardDetail: React.FC = () => {

    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);
    const [subTable, setSubTable] = useState(false);
    const toggleEarnSubTableDropdown = () => setSubTable(!subTable);
    const [bondId, setId] = useState(0);
    const [qty, setQty] = useState(1);
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const [allocateName, setAllocateName] = useState<string[]>([]);
    const [allocateEmail, setAllocateEmail] = useState<string[]>([]);
    const [allocateMobile, setAllocateMobile] = useState<string[]>([]);
    const [allocateWhatsapp, setAllocateWhatsapp] = useState<boolean[]>([]);
    const [allocateEmailChecked, setAllocateEmailChecked] = useState<boolean[]>([]);
    const [allocationType, setAllocationType] = useState(1);
    const [alertPopup, setAlertPopup] = useState(false);
    const [allocationData, setAllocationData] = useState<APIData.AllocationDetails>();
    const [allocationListData, setAllocationListData] = useState<APIData.AllocationTableData[]>([]);
    const [applicationPopup, setApplicationPopup] = useState(false);
    let InterstEarned = 0;


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAllocation = (index: number, allocation_id: number) => {
        setShowPreloader(true);
        ServerAPI.ipoFormAllocation(allocation_id, allocateName[index], allocateEmail[index], allocateMobile[index], allocateEmailChecked[index] == true ? 1 : 0, allocateWhatsapp[index] == true ? 1 : 0).then((response: any) => {
            if (response != undefined) {
                toast.success(response['message']);
                navigate(PageLinks.ALLOCATION_SUCESS);
            }

        }).finally(() => {
            setShowPreloader(false);
        })
    }


    useEffect(() => {
        if (location.pathname) {
            const id = location.pathname.split("/").pop();
            setId(parseInt(id!));
        }
        setAllocationType(1);
    }, []);

    const formAllocationDetails = () => {
        if (bondId != 0) {
            setShowPreloader(true);
            ServerAPI.GetFormAllocations(bondId).then((response: any) => {
                if (response != undefined) {
                    setAllocationData(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    useEffect(() => {
        formAllocationDetails();
    }, [bondId])

    const AllocationList = () => {
        ServerAPI.ipoFormAllocationList(50, 1, bondId, allocationType, searchText).then((response: any) => {
            if (response['data'] != undefined) {
                setAllocationListData(response['data']);
            }
        })
    }

    useEffect(() => {
        if (allocationType != 0) {
            AllocationList();
        }
    }, [allocationType])

    useEffect(() => {
        AllocationList();
    }, [bondId, location])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <main>
                <section className="mt-0 pt-110 pb-30 pb-md-130 pt-md-130 pt-xs-150 pb-xs-10">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li>Dashboard</li>
                                            <li className="active">Details</li>
                                        </ul>
                                    </nav>
                                </div>
                                <p className="dashboard-title mt-2">Bonds / IPO Details</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid">
                        <div className="row align-items-center pt-200 detail-view-description">
                            <div className="row  pb-3">
                                <div className="col-lg-1 col-md-3 col-sm-6">
                                    <img src={allocationData?.bond_logo != '' ? allocationData?.bond_logo : "assets/img/img_placeholder.png"} alt="sovereign" className="bond-title-img" />
                                </div>
                                <div className="col-lg-11 col-md-9 col-sm-6 ">
                                    <div className="d-flex my-2">
                                        <p className="title"><b> {allocationData?.bond_name}</b></p>
                                    </div>
                                    <div className="cursor text-end" onClick={(e: any) => {
                                        e.preventDefault();
                                        setApplicationPopup(true);

                                    }}>
                                        <button className="dealer-dashboard-application-button"><i className="fa fa-download" aria-hidden="true">&nbsp;</i>Download Application Form</button>
                                    </div>
                                    {/* <div className="d-flex my-2">
                                        <p className="fs-15"> <span className="success-popup-text">Allocation Date&nbsp;08 / 12 / 2022</span></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 section-left mt-2">
                            <table className="w-100 ipo-specific-terms mb-3 text-center">
                                <thead>
                                    <tr>
                                        <th className="br-top-12">Form Details</th>
                                        <th className="right-br-ipo-specific-terms bottom-br-ipo-specific-terms">Total No. Of Forms</th>
                                        <th className="right-br-ipo-specific-terms bottom-br-ipo-specific-terms">Allocated</th>
                                        <th className="right-br-ipo-specific-terms bottom-br-ipo-specific-terms">Remaining</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Single Page</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.single_total}</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.single_allocated}</td>
                                        {allocationData?.dealer_form_allocations.single_pages[0] != undefined && allocationData?.dealer_form_allocations.single_pages[0].remaining != undefined ?
                                            <td className="right-br-ipo-specific-terms">{allocationData?.dealer_form_allocations.single_pages[0].remaining}</td>
                                            :
                                            <td className="right-br-ipo-specific-terms">0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>Two Page</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.two_total}</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.two_allocated}</td>
                                        {allocationData?.dealer_form_allocations.two_pages[0] != undefined && allocationData?.dealer_form_allocations.two_pages[0].remaining != undefined ?
                                            <td className="right-br-ipo-specific-terms">{allocationData?.dealer_form_allocations.two_pages[0].remaining}</td>
                                            :
                                            <td className="right-br-ipo-specific-terms">0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>Abridged</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.abridged_total}</td>
                                        <td className="right-br-ipo-specific-terms">{allocationData?.abridged_allocated}</td>
                                        {allocationData?.dealer_form_allocations.abridged_pages[0] != undefined && allocationData?.dealer_form_allocations.abridged_pages[0].remaining != undefined ?
                                            <td className="right-br-ipo-specific-terms">{allocationData?.dealer_form_allocations.abridged_pages[0].remaining}</td>
                                            :
                                            <td className="right-br-ipo-specific-terms">0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="ipo-purchase-series"> Grand Total</td>
                                        <td className="right-br-ipo-specific-terms">
                                            <b>{allocationData?.single_total! + allocationData?.two_total! + allocationData?.abridged_total!}</b></td>
                                        <td className="right-br-ipo-specific-terms">
                                            <b>{allocationData?.single_allocated! + allocationData?.two_allocated! + allocationData?.abridged_allocated!}</b>
                                        </td>
                                        <td className="right-br-ipo-specific-terms">
                                            <b>{(allocationData?.single_total! - allocationData?.single_allocated!) + (allocationData?.two_total! - allocationData?.two_allocated!) + (allocationData?.abridged_total! - allocationData?.abridged_allocated!)}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="ml-3 mt-5">
                            <Tabs>
                                <TabList>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                        setAllocationType(1);
                                    }}>Single Page Form</Tab>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                        setAllocationType(2);
                                    }}>Two Page Form</Tab>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                        setAllocationType(3);
                                    }}>Abridged Prospectus</Tab>
                                </TabList>

                                <TabPanel className="react-tabs__tab-panel-dasboard">
                                    <section className="page-title-area listing-search fix pb-20 pt-2">
                                        <div className="">
                                            {/* <div className="row align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="widget-search-content faq-que-search">
                                                        <form className="subscribe-form" action="">
                                                            <i className="far fa-search"></i>
                                                            <input type="text" placeholder="Search By any.."  onChange={(e:any)=>{
                                                                e.preventDefault();
                                                                setSearchText(e.target.value);
                                                            }}/>
                                                            <button className="trustBlackbtn" onClick={(e: any) => {
                                                                e.preventDefault();
                                                            }}>Search</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </section>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive pt-5" data-pattern="priority-columns">
                                            <table id="tech-companies-1" className="table table table-bordered dashboard-detail">
                                                <thead className="dashboard-detail-tab-table">
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>App.Number</th>
                                                        <th>Customer Name</th>
                                                        <th>Email ID</th>
                                                        <th>Mobile Number</th>
                                                        <th>Send  Via</th>
                                                        <th>Download</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allocationListData != undefined && allocationListData.length > 0 && allocationListData.map((listdata: APIData.AllocationTableData, index: number) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{listdata.dealer_customer_allocation_app_no}</td>
                                                                <td><input required={true} placeholder="Enter Full Name" value={listdata.dealer_customer_allocation_customer_name} onChange={(e: any) => {
                                                                    let newName = [...allocateName];
                                                                    newName[index] = e.target.value;
                                                                    setAllocateName(newName)
                                                                }}
                                                                    className={listdata.dealer_customer_allocation_customer_name != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Email ID" value={listdata.dealer_customer_allocation_email} onChange={(e: any) => {
                                                                    let newEmail = [...allocateEmail];
                                                                    newEmail[index] = e.target.value;
                                                                    setAllocateEmail(newEmail);
                                                                }} className={listdata.dealer_customer_allocation_email != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Mobile No" value={listdata.dealer_customer_allocation_phone} maxLength={10} onChange={(e: any) => {
                                                                    let newMobile = [...allocateMobile];
                                                                    newMobile[index] = e.target.value;
                                                                    setAllocateMobile(newMobile);
                                                                }} className={listdata.dealer_customer_allocation_phone ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td> <div className="d-flex">
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newEmail = [...allocateEmailChecked];
                                                                        newEmail[index] = e.target.checked;
                                                                        setAllocateEmailChecked(newEmail);
                                                                    }}

                                                                    />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Email
                                                                        </p>
                                                                    </label>&nbsp;&nbsp;
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newWhatsapp = [...allocateWhatsapp];
                                                                        newWhatsapp[index] = e.target.checked;
                                                                        setAllocateWhatsapp(newWhatsapp);
                                                                    }} />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Whatsapp
                                                                        </p>
                                                                    </label>
                                                                </div></td>
                                                                <td className="text-center cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    window.open(listdata.dealer_customer_allocation_pdf);
                                                                }}><i className="fa fa-download" aria-hidden="true"></i></td>
                                                                <td className="dashboard-tab-table-allocated">
                                                                    {listdata.dealer_customer_allocation_is_submit == 0 ?
                                                                        <button className="dashboard-tab-table-button-filled" onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            handleAllocation(index, listdata.dealer_customer_allocation_id);
                                                                        }}>Submit</button>
                                                                        :
                                                                        "Allocated"
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>


                                            </table>
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel className="react-tabs__tab-panel-dasboard">
                                    <section className="page-title-area listing-search fix pb-20 pt-5">
                                        {/* <div className="">
                                            <div className="row align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="widget-search-content faq-que-search">
                                                        <form className="subscribe-form" action="">
                                                            <i className="far fa-search"></i>
                                                            <input type="text" placeholder="Search By any.." />
                                                            <button className="trustBlackbtn" onClick={(e: any) => {
                                                                e.preventDefault();
                                                            }}>Search</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </section>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive pt-5" data-pattern="priority-columns">
                                            <table id="tech-companies-1" className="table table table-bordered dashboard-detail">
                                                <thead className="dashboard-detail-tab-table">
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>App.Number</th>
                                                        <th>Customer Name</th>
                                                        <th>Email ID</th>
                                                        <th>Mobile Number</th>
                                                        <th>Send  Via</th>
                                                        <th>Download</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allocationListData != undefined && allocationListData.length > 0 && allocationListData.map((listdata: APIData.AllocationTableData, index: number) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{listdata.dealer_customer_allocation_app_no}</td>
                                                                <td><input required={true} placeholder="Enter Full Name" value={listdata.dealer_customer_allocation_customer_name} onChange={(e: any) => {
                                                                    let newName = [...allocateName];
                                                                    newName[index] = e.target.value;
                                                                    setAllocateName(newName)
                                                                }}
                                                                    className={listdata.dealer_customer_allocation_customer_name != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Email ID" value={listdata.dealer_customer_allocation_email} onChange={(e: any) => {
                                                                    let newEmail = [...allocateEmail];
                                                                    newEmail[index] = e.target.value;
                                                                    setAllocateEmail(newEmail);
                                                                }} className={listdata.dealer_customer_allocation_email != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Mobile No" value={listdata.dealer_customer_allocation_phone} maxLength={10} onChange={(e: any) => {
                                                                    let newMobile = [...allocateMobile];
                                                                    newMobile[index] = e.target.value;
                                                                    setAllocateMobile(newMobile);
                                                                }} className={listdata.dealer_customer_allocation_phone ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td> <div className="d-flex">
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newEmail = [...allocateEmailChecked];
                                                                        newEmail[index] = e.target.checked;
                                                                        setAllocateEmailChecked(newEmail);
                                                                    }}

                                                                    />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Email
                                                                        </p>
                                                                    </label>&nbsp;&nbsp;
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newWhatsapp = [...allocateWhatsapp];
                                                                        newWhatsapp[index] = e.target.checked;
                                                                        setAllocateWhatsapp(newWhatsapp);
                                                                    }} />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Whatsapp
                                                                        </p>
                                                                    </label>
                                                                </div></td>
                                                                <td className="text-center cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    window.open(listdata.dealer_customer_allocation_pdf);
                                                                }}><i className="fa fa-download" aria-hidden="true"></i></td>
                                                                <td className="dashboard-tab-table-allocated">
                                                                    {listdata.dealer_customer_allocation_is_submit == 0 ?
                                                                        <button className="dashboard-tab-table-button-filled" onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            handleAllocation(index, listdata.dealer_customer_allocation_id);
                                                                        }}>Submit</button>
                                                                        :
                                                                        "Allocated"
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>


                                            </table>
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel className="react-tabs__tab-panel-dasboard">
                                    <section className="page-title-area listing-search fix pb-20 pt-5">
                                        {/* <div className="">
                                            <div className="row align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="widget-search-content faq-que-search">
                                                        <form className="subscribe-form" action="">
                                                            <i className="far fa-search"></i>
                                                            <input type="text" placeholder="Search By any.." />
                                                            <button className="trustBlackbtn" onClick={(e: any) => {
                                                                e.preventDefault();
                                                            }}>Search</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </section>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive pt-5" data-pattern="priority-columns">
                                            <table id="tech-companies-1" className="table table table-bordered dashboard-detail">
                                                <thead className="dashboard-detail-tab-table">
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>App.Number</th>
                                                        <th>Customer Name</th>
                                                        <th>Email ID</th>
                                                        <th>Mobile Number</th>
                                                        <th>Send  Via</th>
                                                        <th>Download</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allocationListData != undefined && allocationListData.length > 0 && allocationListData.map((listdata: APIData.AllocationTableData, index: number) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{listdata.dealer_customer_allocation_app_no}</td>
                                                                <td><input required={true} placeholder="Enter Full Name" value={listdata.dealer_customer_allocation_customer_name} onChange={(e: any) => {
                                                                    let newName = [...allocateName];
                                                                    newName[index] = e.target.value;
                                                                    setAllocateName(newName)
                                                                }}
                                                                    className={listdata.dealer_customer_allocation_customer_name != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Email ID" value={listdata.dealer_customer_allocation_email} onChange={(e: any) => {
                                                                    let newEmail = [...allocateEmail];
                                                                    newEmail[index] = e.target.value;
                                                                    setAllocateEmail(newEmail);
                                                                }} className={listdata.dealer_customer_allocation_email != null ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td><input required={true} placeholder="Enter Mobile No" value={listdata.dealer_customer_allocation_phone} maxLength={10} onChange={(e: any) => {
                                                                    let newMobile = [...allocateMobile];
                                                                    newMobile[index] = e.target.value;
                                                                    setAllocateMobile(newMobile);
                                                                }} className={listdata.dealer_customer_allocation_phone ? "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input dealer-input-data" : "input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"} /></td>
                                                                <td> <div className="d-flex">
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newEmail = [...allocateEmailChecked];
                                                                        newEmail[index] = e.target.checked;
                                                                        setAllocateEmailChecked(newEmail);
                                                                    }}

                                                                    />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Email
                                                                        </p>
                                                                    </label>&nbsp;&nbsp;
                                                                    <input required={true} disabled={listdata.dealer_customer_allocation_via_whatsapp == 1 || listdata.dealer_customer_allocation_via_email == 1 ? true : false} className=" signup" type="checkbox" id="terms" name="terms" onChange={(e: any) => {
                                                                        let newWhatsapp = [...allocateWhatsapp];
                                                                        newWhatsapp[index] = e.target.checked;
                                                                        setAllocateWhatsapp(newWhatsapp);
                                                                    }} />&nbsp;&nbsp;
                                                                    <label htmlFor="terms" className="m-0">
                                                                        <p className="review-text text-left mt-2">
                                                                            Whatsapp
                                                                        </p>
                                                                    </label>
                                                                </div></td>
                                                                <td className="text-center cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    window.open(listdata.dealer_customer_allocation_pdf);
                                                                }}><i className="fa fa-download" aria-hidden="true"></i></td>
                                                                <td className="dashboard-tab-table-allocated">
                                                                    {listdata.dealer_customer_allocation_is_submit == 0 ?
                                                                        <button className="dashboard-tab-table-button-filled" onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            handleAllocation(index, listdata.dealer_customer_allocation_id);
                                                                        }}>Submit</button>
                                                                        :
                                                                        "Allocated"
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>


                                            </table>
                                        </div>

                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </section >
            </main >
            <ApplicationPopup showModal={applicationPopup} closeModal={() => { setApplicationPopup(false) }} ipoList={() => { formAllocationDetails() }} BondID={bondId} AllocationList={() => { AllocationList() }} />
            <Footer />
        </div >
    )
}

export default DashboardDetail;
