import { url } from "inspector";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Header from "../common/Header";
import SlideBar from "../components/SlideBar";
import Footer from "../common/Footer";

const WhatAreAdvantages: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="what_are_advantages pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li>News and Insights</li>
                                            <li className="active">What Are Advantages</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex">Tags:&nbsp;
                                    <span className="mt-1">
                                        <div className="triangle-left-adv"></div>
                                        <div className="tag-adv">Bonds and Deb</div>
                                    </span>&nbsp;
                                    <span className="mt-1">
                                        <div className="triangle-left-adv"></div>
                                        <div className="tag-adv">Investing In India</div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 what_are_advantages_full">
                            <div className="col-lg-8 what_are_advantages_full_left">
                                <div className="story_box">
                                    <div>
                                       
                                    </div>
                                    <div className="story_box_left subscribe-area">
                                    <div className="story_box_right_date pt-2" style={{color:"#22263D"}}>Jan 24, 2022</div>
                                        <div className="triangle-left mt-2"></div>
                                        <div className="story_box_right_mins mt-2">5 min read</div>
                                        <p className="what_are_advantages-p sourceSansPro-semibold">What are <br />Advantages of Investing <br />in Bond IPO</p>
                                    </div>
                                </div>
                                <div className="what_are_advantages_full_left_text">
                                    <p>
                                        The stock market is the first thing that comes to most people’s minds when they think of investments. True, stock markets are exciting, and there are numerous stories of people making fortunes and becoming wealthy overnight. Bonds, while commonly thought to be a viable investment option, do not hold the same allure. To the average person, the jargon appears obscure, and many people find it uninteresting; this is especially true during exhilarating bull markets.
                                    </p>
                                    <p>
                                        Bonds, on the other hand, are widely recognized for their safety and security, and many investors include them in their portfolios.
                                    </p>
                                    <p>
                                        In many cases, the required amount exceeds the amount that can be borrowed from a bank. As a result, these companies must sell their bonds on the open market. As a result, many investors contribute to the fund-raising effort by lending a portion of the funds needed. Bonds are similar to loans in that the investor acts as the lender. The corporation or organization that sells the bonds is known as the issuer. Bonds can be viewed as IOUs issued by the issuer to the lender, in this case, the investor.
                                    </p>
                                    <p>
                                        No one would lend money for free, so the bond issuer pays a premium in the form of interest to use the funds. The interest on the bonds is paid on a set schedule and at a set rate. The interest rate on bonds is commonly referred to as a “coupon.” A loan’s face value is the amount borrowed, and the maturity date is the date the loan must be returned. Bonds are defined as fixed income instruments because the investor knows how much money he or she will receive if the bond is held to maturity. Bonds are less risky than stocks, but they also have lower returns.
                                    </p>
                                    <p>
                                        Examining an IPO’s credibility and promoter’s track record The company’s products and services, as well as it’s potential Performance of the company that is launching the IPO in the past.
                                    </p>
                                    <p>
                                        Cost of the project, funding options, and projected profit Involves risk factors.
                                    </p>
                                    <p className="text_bold">BENEFITS OF IPO INVESTING</p>
                                    <p className="text_light_bold">1) Get A Head Start On The Competition.</p>
                                    <p>
                                        Investing in an initial public offering (IPO) allows you to get in on the ‘ground floor’ of a high-growth company. An initial public offering (IPO) could be your key to making quick money in a short amount of time. It could also help you expand your wealth over time.
                                    </p>
                                    <p>
                                        Assume you’ve made a strategic investment in a company that sells disruptive technologies. You will benefit as well if it succeeds in swaying the market and making money.
                                    </p>
                                    <p className="text_light_bold">2) Meet Long-Term Goals</p>
                                    <p>
                                        Initial public offerings (IPOs) involve equity investments.
                                    </p>
                                    <p>
                                        As a result, they can generate significant profits over the long haul. You can use the money you’ve saved to help you reach long-term financial goals such as retirement or homeownership.
                                    </p>
                                    <p className="text_light_bold">3) More Price Transparency</p>
                                    <p>
                                        The price for each security issued is specified directly in the IPO order paper. As a result, you’ll have access to the same information as bigger investors.
                                    </p>
                                    <p>
                                        This would change in the post-IPO situation. A stockbroker’s best offer and market fluctuations will determine the share price following the IPO.
                                    </p>
                                    <p className="text_light_bold">4) Buy Low And Make A Lot Of Money</p>
                                    <p>
                                        The IPO pricing is typically the best value if you want to invest in a small company with the potential to grow big. This is due to the prospect of receiving a reduced fee from the business. It may be difficult to invest in that promising firm if you miss the IPO window since its stock price may rise.
                                    </p>
                                    <p className="text_light_bold">To Summaries</p>
                                    <p>
                                        Companies can raise funds by selling shares in an initial public offering (IPO). Stock can be given as a bonus, as an incentive, or as part of an employment contract. This is occasionally done to keep valued employees. Furthermore, equity can be utilized to buy or sell other firms.
                                    </p>
                                    <p>
                                        When a company is listed on a stock exchange, it receives a lot of media attention, which boosts its visibility and makes its products and services more well-known.
                                    </p>
                                    <p>
                                        Investing in an initial public offering (IPO) allows you to get in on the ‘ground floor’ of a high-growth company. An initial public offering (IPO) could be your ticket to making a lot of money in a short amount of time. It could also help you expand your wealth over time.
                                    </p>
                                    <p>
                                        If it succeeds in swaying the market and raking in money, you will benefit from it as well.
                                    </p>
                                </div>
                                <div className="blog_pre_next">
                                    <div className="blog_pre">
                                        <div className="pre_text"><i className="far fa-arrow-left"></i> <span className="text-right">Previous</span></div>
                                        <div className="pre_text_title">How NCD IPO Is Better Than Stock IPO</div>
                                    </div>
                                    <div className="blog_next">
                                        <div className="pre_text"><span className="text-right">Next</span> <i className="far fa-arrow-right"></i> </div>
                                        <div className="pre_text_title">How NCD IPO Is Better Than Stock IPO</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 what_are_advantages_full_right">
                                <div className="your_story_head">
                                    <h3>Related Articles</h3>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">DEBT OR EQUITY? OR A COMBINATION OF BOTH!</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="view_all">
                                    <a href="#" className="explore_btn">View All <i className="fas fa-arrow-right"></i></a>
                                </div>

                                <div className="your_story_head mt-5">
                                    <h3>More from the Author</h3>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">DEBT OR EQUITY? OR A COMBINATION OF BOTH!</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="view_all">
                                    <a href="#" className="explore_btn">View All <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Subscribe />
                <NeedHelp />
            </main>
            <Footer />
        </div>
    )
}

export default WhatAreAdvantages;
